<template>
<main id="main" class="site-main site-main-detail">
    <div class="postercard">
        <div class="postercard__img">
            <img :src="urlPath+'uploads/media/package/thumbnail/600450_'+packagedet.featuredimage" :srcset="urlPath+'uploads/media/package/'+packagedet.featuredimage+'  1400w'" alt="image description">
        </div>
    </div>
    <div class="breadcrumb">
        <div class="container">
            <ul>
                <li><router-link :to="{name:'Home'}">Home</router-link></li>
                <li><span class="live">Booking</span></li>
            </ul>
        </div>
    </div>
    <section class="booking">
        <div class="container">
            <h1 class="folio__title">Online Booking</h1>
            <ul>
                        <li v-for="item in error" v-bind:key="item">
                        {{item}} not valid
                        </li>
                        </ul>
            <form class="general-form booking__form" @submit.prevent="onSubmit">
                <div class="row">
                    <div class="col xs-12 sm-7">
                        <h2 class="booking__form-title">Personal Info</h2>
                        <div class="form-module form-lblstyle">
                            <label for="bkName" class="form-label">Your Name</label>
                            <div class="form-set">
                                <input type="text" id="bkName" v-model="form.bkName" class="form-input" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-module form-lblstyle">
                            <label for="bkEmail" class="form-label">Email Address</label>
                            <div class="form-set">
                                <input type="email" v-model="form.bkEmail" id="bkEmail" class="form-input" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-module form-lblstyle">
                            <label for="bkTel" class="form-label">Contact Number</label>
                            <div class="form-set">
                                <input type="tel" v-model="form.bkTel" id="bkTel" class="form-input" autocomplete="off">
                            </div>
                        </div>
                        <div class="form-module form-lblstyle">
                            <label for="bkCountry" class="form-label">Country</label>
                            <div class="form-set">
                                <select id="bkCountry" v-model="form.bkCountry" class="form-input">
                                    <option value="" disabled selected>&nbsp;</option>
                                    <option value="1">Australia</option>
                                    <option value="2">Nepal</option>
                                    <option value="3">UK</option>
                                    <option value="4">USA</option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <h2 class="booking__form-title">Trip Details</h2>
                        <div class="form-module">
                            <label for="bkTrip" class="form-label">Trip Package</label>
                            <div class="form-set">
                                <select id="bkTrip" v-model="form.bkTrip" class="form-input">
                                    <option>{{packagedet.title}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col xs-12 sm-4">
                                <div class="form-module">
                                    <label for="bkAmount" class="form-label">Amount Per Pax</label>
                                    <div class="form-set">
                                        <input type="text" id="bkAmount" v-model="form.bkAmount" name="bkAmount" class="form-input" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col xs-12 sm-4">
                                <div class="form-module">
                                    <label for="bkPax" class="form-label">No. of Pax</label>
                                    <div class="form-set">
                                        <input type="number" v-model="form.bkPax" id="bkPax" name="bkPax" class="form-input" @change="updatePax" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="col xs-12 sm-4">
                                <div class="form-module">
                                    <label for="bkTotal" class="form-label">Total Amount</label>
                                    <div class="form-set">
                                        <input type="text" id="bkTotal" name="bkTotal" v-model="form.bkTotal" class="form-input" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="col xs-12 sm-4">
                                <div class="form-module">
                                    <label for="bkDepositRange" class="form-label">Choose Initial Deposit</label>
                                    <div class="form-set">
                                        <select id="bkDepositRange" name="bkDepositRange" v-model="form.bkDepositRange" class="form-input" @change="updateAmount">
                                            <option value=".2">20%</option>
                                            <option value=".4">40%</option>
                                            <option value="1">100%</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col xs-12 sm-4">
                                <div class="form-module">
                                    <label for="bkDepositAmt" class="form-label">Deposit Amount</label>
                                    <div class="form-set">
                                        <input type="text" id="bkDepositAmt" name="bkDepositAmt" v-model="form.bkDepositAmt" class="form-input" disabled>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col xs-12 sm-5">
                        <span class="calendar-title">Select your Trip Start Date from the calendar</span>
                        
                        <div class="calendar">
                            <div id="bookDatepicker"></div>
                            <div id="dateToday" class="calendar-today"><span class="text">Today</span><span class="date">11</span><span class="month">OCTOBER</span></div>
                        </div>
                        <div class="form-highlight">
                            <span class="text">Your Trip Date is </span>
                            <input type="text" id="dateAlternate"  v-model="form.trip_date" readonly>
                        </div>
                        <div class="form-module form-lblstyle">
                            <label for="bkMessage" class="form-label">Additional Info</label>
                            <div class="form-set">
                                <textarea id="bkMessage" v-model="form.bkMessage" cols="30" rows="10" class="form-input"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-action">
                        <input type="submit" @click="sendMessage" class="btn btn-secondary" value="Confirm Booking">
                        
                    </div>
                </div>
            </form>
            
<form action="https://hblpgw.2c2p.com/HBLPGW/Payment/Payment/Payment" method="post" id="hbl_payment_form">
<input type="hidden" name="paymentGatewayID" value="9103336079">
<input type="hidden" name="invoiceNo" value="00000001234567890334">
<input type="hidden" name="productDesc" value="Yoga Training">
<input type="hidden" name="amount" value="000000050000">
<input type="hidden" name="currencyCode" value="840">
<input type="hidden" name="nonSecure" value="N">
<input type="hidden" name="hashValue" value="29E313BDA4F54C05A04AF87CB57B9E5698BDE1CF2D891329646327EB5DA7264E"><p>
    <input type="submit" class="btn btn-success outline" id="submit_hbl_payment_form" value="Proceed Payment"></p></form>
        </div>
    </section>
</main>
</template>

<script>
import "../assets/js/jquery-ui.js";
import jQuery from 'jquery';
import axios from 'axios';
import moment from 'moment';
export default ({
    name: 'Booking',
    props: ['slug','title','tripdate'],
    data() {
        return {
            packagedet: {
                regularprice: '',
                discountedprice: '',
                featuredimage:'',
                urlPath:process.env.VUE_APP_PATH,

            },
            form:{
                bkAmount:'',
                bkPax: 1,
                bkTotal: '',
                bkDepositRange:'.2',
                bkDepositAmt:'',
                bkName:'',
                bkEmail:'',
                bkTel:'',
                bkCountry:'',
                // package_name:'',
                bkTrip:'',
                // package_cost:'',
                // no_pax:'',
                // total_cost:'',
                // deposit_amount:'',
                trip_date:'',
                bkMessage:'',
                

            },
            error:[],
            apiPath:process.env.VUE_APP_API,
            
        }
    },
    async mounted() {
        this.getpackagesdetail(this.$route.params.slug);
        this.getTripdate(this.$route.params.td);
      

        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var d = new Date();
        var date = d.getDate();
        var day = dayNames[d.getDay()];
        jQuery('#dateToday .date').html(date);
        jQuery('#dateToday .month').html(day);
        jQuery("#bookDatepicker").datepicker({
            dateFormat: "d MM, yy, DD",
            inline: true,
            altField:'#dateAlternate',
            altFormat: "d MM, yy, DD"
        });
        
        
        // jQuery('#bookDatepicker').change(function(){
        //     jQuery('#dateAlternate').datepicker('setDate', jQuery(this).val());
        //     // this.form.trip_date=jQuery('#dateAlternate').val();
        //     // console.log(this.form.trip_date);
        // });

        jQuery('.form-lblstyle .form-input').focusin(function () {
            jQuery(this).parents('.form-lblstyle').addClass('label-active');
        });

        jQuery('.form-lblstyle .form-input').focusout(function () {
            var lblStyle = jQuery(this).val();
            if (lblStyle == "") {
                jQuery(this).parents('.form-lblstyle').removeClass('label-active');
            }
        });

    },
    methods: {
        getpackagesdetail(slugurl) {
            axios.get(this.apiPath+'packagebooking/' + slugurl)
                .then((response) => {
                    this.packagedet = response.data[0];
                    this.form.bkTrip = response.data[0].title;

                    // this.packagedet = response.data;
                    // this.routemap = response.data[0]['routemap'];
                    const descEl = document.querySelector('head meta[name="description"]');
                    const keyEl = document.querySelector('head meta[name="keywords"]');
                    const titleEl = document.querySelector('head title');

                    descEl.setAttribute('content', response.data[0]['seo_description']);
                    keyEl.setAttribute('content', response.data[0]['seo_keyword']);
                    titleEl.textContent = response.data[0]['seo_title']?response.data[0]['seo_title']:response.data[0]['title'] + " Booking :: Nepal Alternative Treks";
                })
        },
        getTripdate(tripStart){
            if(tripStart!='null'){
                this.form.trip_date=moment(String(tripStart)).format('DD MMMM, YYYY, dddd');
            }

        },
        updatePax(event) {
            if (this.packagedet.discountedprice) {
                this.form.bkTotal = this.packagedet.discountedprice * event.target.value;
            } else {
                this.form.bkTotal = this.packagedet.regularprice * event.target.value;
            }
            this.form.bkDepositAmt = this.form.bkTotal * this.form.bkDepositRange;
        },
        updateAmount(event){
            this.form.bkDepositAmt = this.form.bkTotal * this.form.bkDepositRange;
        },
        sendMessage(){
            this.error=[];
            for(const item in this.form){
                if(this.form[item]===''){
                    this.error.push(item);
                }
            }
            if(this.error.length===0){
                Email.send({
                    SecureToken: "9851e406-8a43-4d9e-9eac-db0237385302",
                    To : 'suruju.red@gmail.com',
                    From : "suruju.red@gmail.com",
                    Subject : 'Booking for '+this.form.bkTrip,
                    Body : '<b>From:</b> '+ this.form.bkName +'<br/><b>Email:</b> '+this.form.bkEmail+'<b><br/>Country:</b> '+this.form.bkCountry+'<b><br/>Trip Package: </b>'+ this.form.bkTrip+'<b><br/>Contact Number: </b>'+this.form.bkTel+'<b><br/>Trip Cost: </b>'+this.form.bkAmount+'<b><br/>Number of Pax: </b>'+this.form.bkPax+'<b><br/>Total Amount: </b>'+this.form.bkTotal+'<b><br/>Deposited Amount</b>'+this.form.bkDepositAmt+'<b><br/>Message: </b>'+this.form.bkMessage
                    }).then(
                    // message => alert(message),
                this.$router.push({name:'BookingConfirm', params: {fullname: this.form.bkName, depositAmount: this.form.bkDepositAmt, packageName:this.form.bkTrip}})
                );
            }
            // console.log(this.form,this.error);
        },

    },
    updated(){
        if(this.form.bkPax==1){
            if (this.packagedet.discountedprice) {
                this.form.bkTotal = this.packagedet.discountedprice;
                this.form.bkAmount = this.packagedet.discountedprice;
            } else {
                this.form.bkTotal = this.packagedet.regularprice;
                this.form.bkAmount = this.packagedet.regularprice;
            }
        }
        this.form.bkDepositAmt = this.form.bkTotal * this.form.bkDepositRange;
        

    },
    created(){
        jQuery('#bookDatepicker').change(function(){
            jQuery('#dateAlternate').datepicker('setDate', jQuery(this).val());
            this.form.trip_date=jQuery(this).val();
            console.log(this.form.trip_date);
        });
    }

})
</script>
